<template>
  <div class="text-base font-normal">
    <div v-if="deviceModelSelected.id" class="space-x-4 grid text-right">
      <div class="w-full flex flex-row" style="margin-right: -1rem; justify-content: flex-end;">
        <sa-icon-button
          :iconClass="`text-xl bx bx-edit ${$store.state.quickStart.step === 3 ? 'quick-start-not-selected' : ''}`"
          tooltip="Cambiar"
          @click="showDeviceModelDialog(true)" />
        <sa-icon-button
          :iconClass="`text-xl bx bx-list-check ${$store.state.quickStart.step === 3 ? 'quick-start-selected' : ''}`"
          tooltip="Checklist de Revisión"
          @click="showChecklistDialog(true)" />
      </div>
    </div>
    <div
      v-else
      class="space-y-2">
      <div class="w-full flex justify-between">
        <span class="font-bold text-md">Modelo del dispositivo</span>
        <a class="text-purple-400" @click="showNewDeviceModelDialog(true)">
          <strong>+ Agregar nuevo modelo</strong>
        </a>
      </div>
      <div>
        <div class="flex flex-row space-x-2">
          <el-input
            type="text"
            v-model="queryModel.textSearch"
            @input="showDeviceModelDialog(true)">
            <i slot="prefix" class='mt-2 text-xl bx bx-search' />
          </el-input>
          <sa-button
            size="mini"
            type="secondary"
            @click="showDeviceModelDialog(true)">
            Explorar
          </sa-button>
        </div>
      </div>
    </div>
    <el-dialog
      :visible.sync="isVisibleQueryModelDialog"
      :width="dialogClientWidth"
      @opened="onOpenQueryModelDialog"
      @close="onCloseQueryModelDialog">
      <div class="-mx-5">
        <div
          class="flex flex-row items-center px-6 pb-4 border-b">
          <span class="text-md">Buscar modelos</span>
          <a class="text-purple-400 ml-auto" @click="showNewDeviceModelDialog(true)">
            <strong>+ Agregar nuevo modelo</strong>
          </a>
          <div class="ml-4">
            <sa-icon-button
              with-bg
              iconClass="text-xl bx bx-x"
              @click="showDeviceModelDialog(false)" />
          </div>
        </div>
        <div class="px-6 py-4">
          <el-input
            v-model="queryModel.textSearch"
            placeholder="Teclea una marca o un modelo"
            ref="textSearch">
            <i slot="prefix" class='mt-2 text-xl bx bx-search' />
          </el-input>
        </div>
        <div class="max-h-96 overflow-scroll">
          <div
            v-if="deviceModels.length === 0"
            class="py-8 text-center">
            <div
              v-if="queryModel.textSearch"
              class="inline-block w-full">
              <i class="text-3xl bx bx-confused" />
              <div>
                <span>No se encontraron modelos que coincidan con</span>
                <span class="mx-1 italic font-bold">{{ queryModel.textSearch }}</span>
              </div>
            </div>
          </div>
          <div
            v-for="deviceModel in deviceModels"
            :key="deviceModel.id"
            @click="clickDeviceModel(deviceModel)"
            class="flex flex-row border-t cursor-pointer hover:bg-gray-50">
            <div class="w-full px-4 py-2">
              <div class="flex flex-row items-center w-full space-x-2">
                <span>
                  <i :class="[ deviceModel.id === deviceModelSelected.id
                    ? 'bx bx-radio-circle-marked text-xl text-purple-500'
                    : 'bx bx-radio-circle text-xl text-gray-300']" />
                </span>
                <div class="px-2">
                  <span class="font-bold">{{ deviceModel.model }} - {{ deviceModel.brand }}</span>
                  <span class="block">{{ CategoryEnumDict[deviceModel.deviceType] }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="px-6 pt-6 border-t">
          <div class="flex flex-row justify-end space-x-2">
            <sa-button
              type="secondary"
              @click="showDeviceModelDialog(false)">
              Cancelar
            </sa-button>
            <sa-button
              type="primary"
              @click="selectDeviceModel">
              Seleccionar
            </sa-button>
          </div>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      :visible.sync="isVisibleChecklistDialog"
      :width="dialogClientWidth"
      @opened="onOpenChecklistDialog"
      @close="onCloseChecklistDialog">
      <checklist-items-list :items="items" />
    </el-dialog>
    <el-dialog
      :visible.sync="isVisibleNewDeviceModelDialog"
      :width="dialogClientWidth">
      <new-device-model-form
        @onCancel="showNewDeviceModelDialog(false);"
        @onSave="onDeviceModelCreate"/>
    </el-dialog>
  </div>
</template>
<style>
.quick-start-not-selected {
  padding: 4px;
  border: 8px solid #FFFFFF;
}
</style>
<script>
import _ from 'lodash';
import error from '@/mixins/error';
import responsiveSize from '@/mixins/responsiveSize';
import CatalogService from '@/services/CatalogService';
import { CategoryEnumDict } from '@/constants';
import { mappers } from '../store';
import NewDeviceModelForm from "./NewDeviceModelForm";
import { QUICK_START_STORE } from "@/store/modules/quick-start/mutation-types";

export default {
  name: 'DeviceModelBlock',
  components: {
    NewDeviceModelForm,
    ChecklistItemsList: () => import('../checklist-items/ChecklistItemsList'),
  },
  mixins: [ error, responsiveSize ],
  props: {
    orderDevice: Object,
  },
  data() {
    return {
      dialogClientWidth: '40%',
      queryModel: {
        textSearch: null,
        pageSize: 10,
        pageNumber: 1,
      },
      isVisibleQueryModelDialog: false,
      isVisibleChecklistDialog: false,
      isVisibleNewDeviceModelDialog: false,
      deviceModels: [],
      deviceModelsMetadata: [],
      deviceModelSelected: this.orderDevice || { id: null },
      CategoryEnumDict,
      items: [],
    }
  },
  created() {
    window.addEventListener("resize", _.debounce(this.resizeDialogWidth, 200));
    this.resizeDialogWidth();
  },
  destroyed() {
    window.removeEventListener("resize", _.debounce(this.resizeDialogWidth, 200));
  },
  watch: {
    'queryModel.textSearch': {
      deep: true,
      handler: _.debounce(async function () {
        await this.loadDeviceModels(this.queryModel)
      }, 300)
    },
  },
  computed: {
    deviceBrand() {
      if (this.deviceModelSelected.brand) return this.deviceModelSelected.brand;
      return this.deviceModelSelected.deviceModel.brand;
    },
    deviceModel() {
      if (this.deviceModelSelected.model) return this.deviceModelSelected.model;
      return this.deviceModelSelected.deviceModel.model;
    },
  },
  methods: {
    onDeviceModelCreate(deviceModel) {
      this.clickDeviceModel(deviceModel);
      this.showNewDeviceModelDialog(false);
    },
    clickDeviceModel(deviceModel) {
      const newChecklist = this.$store.state.checklistItems.entities
        .map((entity) => ({ checklistId: entity.id, name: entity.name, value: false }));
      this.deviceModelSelected = deviceModel;
      this.deviceModelSelected.checklistItems = newChecklist;
      this.showDeviceModelDialog(false)
      this.$emit('onDeviceModelSelected', this.deviceModelSelected);
    },
    selectDeviceModel() {
      this.showDeviceModelDialog(false)
      this.$emit('onDeviceModelSelected', this.deviceModelSelected)
    },
    showDeviceModelDialog(show) {
      this.isVisibleQueryModelDialog = show
    },
    showChecklistDialog(show) {
      this.isVisibleChecklistDialog = show;
    },
    showNewDeviceModelDialog(show) {
      this.isVisibleNewDeviceModelDialog = show;
    },
    onCloseQueryModelDialog() {
      this.queryModel.textSearch = null
      this.deviceModels = []
    },
    onOpenQueryModelDialog() {
      this.$refs.textSearch.focus()
    },
    onOpenChecklistDialog() {
      this.items = mappers.mergeChecklistItems(this.deviceModelSelected.checklistItems);
    },
    onCloseChecklistDialog() {
      this.orderDevice.checklistItems = this.items;
      this.deviceModelSelected.checklistItems = this.items;
      this.items = [];
    },
    async loadDeviceModels(queryModel) {
      try {
        const result = await CatalogService.getAllDeviceModels(queryModel)
        this.deviceModels = result.data
        this.deviceModelsMetadata = result.metadata
      }
      catch(error) {
        this.$message.error(this.getErrorMessage(error))
      }
    },
    resizeDialogWidth() {
      this.dialogClientWidth = this.getPercentageWidth();
    },
    handleNextStep() {
      const step = this.$store.state.quickStart.step;
      this.$store.dispatch(QUICK_START_STORE, { step: step + 1 });
    },
  },
};
</script>
