<template>
  <div class="px-2">
    <div
      class="w-full flex flex-row items-center">
      <span class="text-md font-bold">Agregar nuevo modelo</span>
      <div class="ml-auto">
        <sa-icon-button
          with-bg
          iconClass="text-xl bx bx-x"
          @click="cancel"/>
      </div>
    </div>
    <div class="w-full flex flex-col pt-2 space-y-4">
      <div class="w-full flex">
        <div class="w-full flex flex-row" style="justify-content: space-between;">
          <span>Categoría</span>
          <a class="text-purple-400" @click="showCreateCategoryDialog(true)">
            <strong>+ Agregar nueva categoría</strong>
          </a>
        </div>
      </div>
      <div class="flex">
        <div class="w-full flex flex-row">
          <el-input
            type="text"
            class="mr-2"
            v-model="queryModel.textSearch"
            @input="showQueryModelDialog(true)">
            <i slot="prefix" class='mt-2 text-xl bx bx-search' />
          </el-input>
          <el-button @click="showQueryModelDialog(true)">
            Explorar
          </el-button>
        </div>
      </div>
    </div>
    <div class="flex flex-row py-4 space-x-2">
      <div class="w-1/2">
        <span>Marca</span>
        <el-input
          class="w-full"
          v-model="form.brand" />
      </div>
      <div class="w-1/2">
        <span>Modelo</span>
        <el-input
          class="w-full"
          v-model="form.model" />
      </div>
    </div>
    <div class="py-2">
      <div class="flex flex-row justify-end space-x-2">
        <el-button @click="cancel">
          Cancelar
        </el-button>
        <sa-button
          type="primary"
          @click="save">
          Guardar
        </sa-button>
      </div>
    </div>
    <el-dialog
      append-to-body
      :visible.sync="isVisibleQueryModelDialog"
      :width="dialogClientWidth"
      @opened="onOpenQueryModelDialog">
      <div class="-mx-5">
        <div
          class="flex flex-row items-center px-6 pb-4 border-b"
          style="justify-content: space-between;">
          <span class="text-md">Buscar categorías</span>
          <div class="ml-4">
            <sa-icon-button
              with-bg
              iconClass="text-xl bx bx-x"
              @click="showQueryModelDialog(false)" />
          </div>
        </div>
        <div class="px-6 py-4">
          <el-input
            v-model="queryModel.textSearch"
            placeholder="Teclea una categoría"
            ref="textSearch">
            <i slot="prefix" class='mt-2 text-xl bx bx-search' />
          </el-input>
        </div>
        <div class="max-h-96 overflow-scroll">
          <div
            v-for="deviceCategory in deviceCategories"
            :key="deviceCategory.id"
            @click="categoryChange(deviceCategory)"
            class="flex flex-row border-t cursor-pointer hover:bg-gray-50">
            <div class="w-full px-4 py-2">
              <div class="flex flex-row items-center w-full space-x-2">
                <span>
                  <i :class="[ deviceCategory.id === form.category?.id
                    ? 'bx bx-radio-circle-marked text-xl text-purple-500'
                    : 'bx bx-radio-circle text-xl text-gray-300']" />
                </span>
                <div class="px-2">
                  <span class="font-bold">{{ deviceCategory.name }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="px-6 pt-6 border-t">
          <div class="flex flex-row justify-end space-x-2">
            <sa-button
              type="secondary"
              @click="showQueryModelDialog(false)">
              Cancelar
            </sa-button>
            <sa-button
              type="primary"
              @click="selectCategory">
              Seleccionar
            </sa-button>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import _ from 'lodash';
import error from '@/mixins/error';
import responsiveSize from '@/mixins/responsiveSize';
import CatalogService from "@/services/CatalogService";
import CategoryDialogService, { command as CategoryDialogCommand, commands } from '@/components/dialogs/Category/service';
import CategoryEnum from '@/components/dialogs/Category/enum';
import OrderService from "../../../../../services/OrderService";

export default {
  name: 'NewDeviceModelForm',
  mixins: [
    error,
    responsiveSize,
  ],
  data() {
    return {
      dialogClientWidth: '40%',
      queryModel: {
        textSearch: null,
        pageSize: 10,
        pageNumber: 1,
      },
      isVisibleQueryModelDialog: false,
      commandSubscription: null,
      errorMessage: undefined,
      form: {
        category: undefined,
        brand: undefined,
        model: undefined,
      },
      deviceCategories: [],
    };
  },
  created() {
    this.commandSubscription = CategoryDialogCommand.subscribe(this.customCommands);
    window.addEventListener("resize", _.debounce(this.resizeDialogWidth, 200));
    this.resizeDialogWidth();
  },
  destroyed() {
    this.commandSubscription.unsubscribe();
    window.removeEventListener("resize", _.debounce(this.resizeDialogWidth, 200));
  },
  watch: {
    'errorMessage': {
      deep: true,
      handler: _.debounce(async function () {
        if (this.errorMessage) {
          await this.$toastr.e(this.errorMessage);
          this.errorMessage = undefined;
        }
      }, 300)
    },
    'queryModel.textSearch': {
      deep: true,
      handler: _.debounce(async function () {
        await this.loadDeviceCategories(this.queryModel);
      }, 300)
    },
  },
  methods: {
    customCommands({ type, payload }) {
      switch (type) {
        case commands.create:
          this.categoryCreate(payload);
          break;
        default:
      }
    },
    categoryCreate(category) {
      if (category.type === CategoryEnum.Device) {
        this.categoryChange(category);
      }
    },
    categoryChange(category) {
      this.queryModel.textSearch = category.name;
      this.form.category = category;
    },
    selectCategory() {
      this.queryModel.textSearch = this.form.category.name;
      this.isVisibleQueryModelDialog = false;
    },
    resizeDialogWidth() {
      this.dialogClientWidth = this.getPercentageWidth();
    },
    showCreateCategoryDialog() {
      CategoryDialogService.show({ type: CategoryEnum.Device });
    },
    showQueryModelDialog(show) {
      this.isVisibleQueryModelDialog = show;
    },
    onOpenQueryModelDialog() {
      this.$refs.textSearch.focus();

    },
    cancel() {
      this.$emit('onCancel');
      this.form = {
        category: undefined,
        brand: undefined,
        model: undefined,
      };
      this.queryModel.textSearch = null;
    },
    async loadDeviceCategories(queryModel) {
      const categories = await OrderService.getDeviceTypes();
      this.deviceCategories = categories
        .filter((c) => c.name.toLowerCase().includes(queryModel.textSearch?.toLowerCase()));
    },
    async save() {
      try {
        const device = await CatalogService.createDeviceModel({
          categoryId: this.form.category.id,
          brand: this.form.brand,
          model: this.form.model,
        });
        this.form = {
          category: undefined,
          brand: undefined,
          model: undefined,
        };
        this.$toastr.s('Se creo con exito');
        this.$emit('onSave', device);
      } catch (e) {
        this.errorMessage = this.getErrorMessage(e);
      }
    },
  },
}
</script>
